<template>
  <div class="shop_quality_details">
    <div class="details">
      <img class="tel" :src="'./img/qualityTesting_tel.png'" alt="">
      <img class="text" :src="'./img/qualityTesting_text.png'" v-if="type === 1" alt="" style="width: auto; height: 104px; display: block">
      <img class="text" :src="'./img/qualityTesting_text01.png'" v-if="type === 2" alt="" style="width: auto; height: 70px; display: block">
      <img class="text" :src="'./img/qualityTesting_text02.png'" v-if="type === 3" alt="" style="width: auto; height: 70px; display: block">
<!--      <p class="text">服务时间：周一至周五09:00-17:30</p>-->
<!--      <div class="text">-->
<!--        <span>检测中心：</span>-->
<!--        <span>陕西省西咸新区沣东新城协同创新港8号楼<br>东莞市长安镇长盛社区振安东路249号</span>-->
<!--      </div>-->
    </div>
    <img class="wechat" :src="'./img/qualityTesting_wechat.png'" alt="">
  </div>
</template>

<script>
export default {
name: "contract",
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
